import "./App.css";
import { Element, Link } from "react-scroll";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="mb-0 jumbotron jumbotron-fluid">
          <div className="container">
            <h1 className="jumbotron-text display-4">JEREMIE</h1>
            <h1 className="cursive-text display-6">and</h1>
            <h1 className="jumbotron-text display-4">KERRY</h1>
            <h4 className="pt-3 cursive-text font-italic">
              Saturday November 13, 2021
            </h4>
          </div>
        </div>
        <nav className="navbar navbar-expand-sm navbar-light bg-light justify-content-center">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to="our-story"
                activeClass="active"
                spy={true}
                offset={20}
                duration={500}
                className="nav-link"
                href="#"
              >
                Our Story
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="reception"
                activeClass="active"
                spy={true}
                offset={20}
                duration={500}
                className="nav-link"
                href="#"
              >
                Reception Details
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://www.myregistry.com/wedding-registry/kerry-jackson-and-jeremie-laval-acton-ma/2943717/giftlist"
                target="_blank"
              >
                Our Registry
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <div
          id="pictures"
          className="carousel slide mh-75"
          data-bs-ride="carousel"
          data-bs-interval="3000"
        >
          <div className="carousel-inner">
            {[
              "alsace",
              "brooklyn",
              "concert",
              "eiffel",
              "iceland",
              "perouge",
              "smokies",
            ].map((img, i) => (
              <div
                className={`carousel-item ${i == 0 ? "active" : ""}`}
                key={img}
              >
                <img
                  src={`photos/${img}.jpg`}
                  className="img-fluid d-block m-auto"
                  alt="..."
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <img src="heart.svg" className="separator" />
        </div>
        <Element name="our-story" className="element">
          <div className="container text-center">
            <h1 className="cursive-text display-4">Our Story</h1>
            <div id="our-story">
              <p className="lead">
                Our story begins back in 2015 when we met at Madalyn and
                Wesley's Halloween party. 🎃 After a game of Spot It and some
                fun conversations in French, we left the party knowing we would
                like to see each other again.
              </p>
              <p className="lead">
                Luckily, this happened two weeks later when Madalyn had to
                cancel on Kerry for a dinner date - Jeremie gladly stepped in
                and took her place. After that first date, along with Jeremie's
                amazing crepe making skills and surprising Kerry with a night
                out to see the Nutcracker, you could say that Kerry had been won
                over. 😀
              </p>
              <p className="lead">
                Six years and two chinchillas later, we are making it official
                by eloping in Alaska in September. We are so happy to celebrate
                our love story with you in November. 💘
              </p>
            </div>
          </div>
        </Element>
        <img src="champagne.svg" className="separator" />
        <Element name="reception" className="element">
          <div className="container text-center">
            <h1 className="cursive-text display-4 mb-4">Reception Details</h1>
            <dl className="lead">
              <dt>Where</dt>
              <dd>79 Robbins St Acton, MA</dd>

              <dt>When</dt>
              <dd>4pm</dd>
            </dl>
            {/* <ul className="lead">
              <li>Where: 79 Robbins St Acton, MA</li>
              <li>When: 4 PM</li>
            </ul> */}
          </div>
        </Element>
      </main>
      <hr className="mt-5"></hr>
      <footer className="pt-2 pb-4">Made with ❤️ by Kerry</footer>
    </div>
  );
}

export default App;
